import React from 'react';

// Helper function to convert hex color to rgba
const hexToRgba = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
};

const PainPointSolutionImageToImage = ({
                                           hexColor = '#FFFFFF',
                                           opacity = 1,
                                           leftImage,
                                           rightImage
                                       }) => {
    const backgroundColor = hexToRgba(hexColor, opacity);

    return (
        <div style={{ backgroundColor }} className="px-8 py-[80px] max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row justify-between items-center lg:space-x-4">

                {/* Left Section: Image with flex-grow */}
                <div className="flex-grow flex justify-center">
                    <img
                        src={leftImage}
                        alt="Left Image"
                        className="max-w-full h-auto object-contain"
                    />
                </div>

                {/* Middle Section: 'to' Text */}
                <div className="flex-shrink-0 px-[20px]"> {/* Added mx-6 for more gap */}
                    <h2 className="text-2xl font-bold">to</h2>
                </div>

                {/* Right Section: Image with flex-grow */}
                <div className="flex-grow flex justify-center">
                    <img
                        src={rightImage}
                        alt="Right Image"
                        className="max-w-full h-auto object-contain"
                    />
                </div>
            </div>
        </div>
    );
};

export default PainPointSolutionImageToImage;
