import {useEffect, useRef} from 'react';
import mixpanel from 'mixpanel-browser';
import '../css/home.css';
import PortfolioItem from "../components/PortfolioItem";

import pImage1 from '../images/item-1.png';
import pImage2 from '../images/item-2.png';
import pImage3 from '../images/item-3.png';
import pImage4 from '../images/item-4.png';
import pImage5 from '../images/item-5.png';
import pImage6 from '../images/item-6.png';
import pImage7 from '../images/item-7.png';
import pImage8 from '../images/item-8.png';
import pImage9 from '../images/item-9.png';
import cylinder from '../images/Cylinder.png';
import koala from '../images/koala_health_logo.jpeg';
import fuzzy from '../images/fuzzy_logo.jpeg';
import sendsio from '../images/Sendsio Logo.png';
import Footer from "../components/Footer";
import AboveTheFold from "./AboveTheFold";
import useOnScreen from "../hooks/useOnScreen";

function HomePage() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);
    const isInView3 = useOnScreen(ref3);
    const isInView4 = useOnScreen(ref4);
    const isInView5 = useOnScreen(ref5);
    const isInView6 = useOnScreen(ref6);
    const isInView7 = useOnScreen(ref7);
    const isInView8 = useOnScreen(ref8);
    const isInView9 = useOnScreen(ref9);


    useEffect(() => {
        mixpanel.track('view home page');
    }, []);

    return (
        <div>
            <AboveTheFold />
            <div className={'home-root'} >
                <div className="portfolio mt-10 md:mt-20 main-container">
                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref2}>
                            <PortfolioItem
                                url={'/food-tracking-redesign'}
                                coverImage={pImage2}
                                jobImage={cylinder} // Placeholder for the job image
                                jobTitle="Cylinder"
                                description="Creating a better food tracking experience"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref1}>
                            <PortfolioItem
                                url={'/goal-setting'}
                                coverImage={pImage1}
                                jobImage={cylinder} // Placeholder for the job image
                                jobTitle="Cylinder"
                                description="A new goal setting feature"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref3}>
                            <PortfolioItem
                                url={'/koala-vet-portal-redesign'}
                                coverImage={pImage3}
                                jobImage={koala} // Placeholder for the job image
                                jobTitle="Koala Health"
                                description="KoalaVet: A redesign on an internal tool for vets"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref4}>
                            <PortfolioItem
                                url={'/koala-order-history'}
                                coverImage={pImage4}
                                jobImage={koala} // Placeholder for the job image
                                jobTitle="Koala Health"
                                description="KoalaVet: An internal tool update to include order history and status"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref5}>
                            <PortfolioItem
                                url={'/koala-self-sign-up'}
                                coverImage={pImage5}
                                jobImage={koala} // Placeholder for the job image
                                jobTitle="Koala Health"
                                description="A vet partner self sign up landing page"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref6}>
                            <PortfolioItem
                                url={'/telehealth-funnel'}
                                coverImage={pImage6}
                                jobImage={fuzzy} // Placeholder for the job image
                                jobTitle="Fuzzy"
                                description="A telehealth quiz funnel redesign"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref7}>
                            <PortfolioItem
                                url={'/seo-optimization'}
                                coverImage={pImage7}
                                jobImage={fuzzy} // Placeholder for the job image
                                jobTitle="Fuzzy"
                                description="A blog redesign optimized for SEO"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref8}>
                            <PortfolioItem
                                url={'/pet-care-plan'}
                                coverImage={pImage8}
                                jobImage={fuzzy} // Placeholder for the job image
                                jobTitle="Fuzzy"
                                description="A pet care plan recommendation redesign"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="child project-fade-in full-width" ref={ref9}>
                            <PortfolioItem
                                url={'/sendsio'}
                                coverImage={pImage9}
                                jobImage={sendsio} // Placeholder for the job image
                                jobTitle="Sendsio"
                                description="Fun project: A mobile digital cryptocurrency wallet (Venmo for Crypto)"
                            />
                        </div>
                    </div>

                </div>

                <Footer selected={'home'} />
            </div>
        </div>
    );
}

export default HomePage;
