import React from 'react';
import TitleWithLine from '../components/TitleWithLine';

const ImageTextSection = ({ title, line1, line2, imageSrc, imageAlt, imageSide = 'left' }) => {
    const isImageLeft = imageSide === 'left';
    const marginClass = imageSrc ? 'my-[100px]' : 'my-[0px]'; // Adjust margin based on whether an image is provided

    return (
        <div className="mx-8">
            <div className={`${marginClass} w-full max-w-screen-xl mx-auto`}>
                <div className="mt-10 flex flex-col lg:flex-row items-center lg:items-start">
                    {isImageLeft && imageSrc && (
                        <div className="w-full lg:w-auto flex justify-center lg:justify-start">
                            <img src={imageSrc} alt={imageAlt} className="w-full max-w-[233px]" />
                        </div>
                    )}
                    <div className={`flex-1 mt-5 lg:mt-0 ${imageSrc ? (isImageLeft ? 'lg:pl-10' : 'lg:pr-10') : ''}`}>
                        <TitleWithLine text={title} side="left" />
                        <div className="text-gray-900 text-base lg:text-lg leading-relaxed mt-5">
                            {line1}
                            <br /><br /> {/* Insert two line breaks */}
                            {line2}
                        </div>
                    </div>
                    {!isImageLeft && imageSrc && (
                        <div className="w-full lg:w-auto flex justify-center lg:justify-end">
                            <img src={imageSrc} alt={imageAlt} className="w-full max-w-[233px]" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageTextSection;
