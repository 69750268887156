import {useEffect} from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import heroImage from '../images/cs/goal-setting/GS1.png';
import gs2 from '../images/cs/goal-setting/GS2.png';
import gs3 from '../images/cs/goal-setting/GS3.png';
import gs4 from '../images/cs/goal-setting/GS4.png';
import gs5 from '../images/cs/goal-setting/GS5.png';
import gs6 from '../images/cs/goal-setting/GS6.png';
import gs7 from '../images/cs/goal-setting/GS7.png';
import gs8 from '../images/cs/goal-setting/GS8.png';
import gs9 from '../images/cs/goal-setting/GS9.svg';
import gs10a from '../images/cs/goal-setting/GS10A.gif';
import gs11 from '../images/cs/goal-setting/GS11.svg';
import gs12a from '../images/cs/goal-setting/GS12A.gif';
import gs13 from '../images/cs/goal-setting/GS13.svg';
import gs14a from '../images/cs/goal-setting/GS14A.gif';
import gs15 from '../images/cs/goal-setting/GS15.svg';
import gs16a from '../images/cs/goal-setting/GS16A.gif';
import gs17 from '../images/cs/goal-setting/GS17.svg';
import gs18a from '../images/cs/goal-setting/GS18A.png';
import gs18c from '../images/cs/goal-setting/GS18C.gif';
import gs31 from '../images/cs/goal-setting/GS31.png';
import PortfolioHero from "../components/PortfolioHero";
import CenteredText from "../components/CenteredText";
import TitleWithLine from "../components/TitleWithLine";
import ImageTextSection from "../components/ImageTextSection";
import PainPointsSection from "../components/PainPointsSection";
import CompetitiveResearch from "../components/CompetitiveResearch";
import FullWidthImage from "../components/FullWidthImage";
import PainPointSolution from "../components/PainPointSolution";
import VideoSideBySide from "../components/VideoSideBySide";

const painPointsData = [
    {
        title: "Limited Time in Appointments to Set Goals",
        description: "Members felt the effect of limited time during appointments, leading to setting goals that were not necessarily what they wanted or felt they could accomplish."
    },
    {
        title: "Hesitation to Adjust Goals Between Appointments",
        description: "Users hesitated to change goals outside appointments, preferring to wait for their care team’s guidance, which limited real-time goal adjustments."
    },
    {
        title: "Difficulty in Tracking Progress",
        description: "Users relied on memory or manual methods to track progress, leading to inconsistent and inaccurate tracking and sometimes forgetting goals."
    },
    {
        title: "Over-Reliance on Appointments for Feedback and Reflection",
        description: "Users felt stuck between appointments, waiting for feedback, validation, or joint reflection with the care team before altering goals."
    },
    {
        title: "Inability to Stay Motivated and Engaged",
        description: "Without the ability to easily track, users struggled to stay motivated and engaged with their goals and thus make progress."
    }
];

const competitiveResearchPoints = [
    "What problem does their product address, and how does it relate to the challenge we’re trying to solve?",
    "What motivates competitors to choose their specific design and approach?",
    "Why do people trust/use a competitor’s product?",
    "Does their product maintain a competitive edge over other solutions in the market? How so?",
    "What solutions are they providing to their users?",
    "What aspects of their approach do I find effective, and what areas could be improved?"
];


const GoalSettingFeature = () => {
    useEffect(() => {
        mixpanel.track('view case study - goal setting');
    }, []);

    return (
        <div className="pt-5 mx-auto">
            <Header selected={'home'}/>
            <PortfolioHero
                backgroundColor="#4D5AF6"
                headerText="Goal Setting Feature"
                bodyText="Cylinder Health is dedicated to empowering individuals with personalized care plans, SMART goals, immediate access to a dedicated care team, tailored content and courses, and ongoing support to help members optimize their gut health."
                heroImage={heroImage}
            />

            <div className={'pt-10'}>
                <FullWidthImage src={gs2} />
            </div>

            <div className="flex justify-center mx-8">
                <div className="my-10 w-full max-w-screen-xl">
                    <CenteredText text={'Background'} />
                    <ImageTextSection
                        title="Context"
                        line1="A crucial aspect of Cylinder’s offerings is the Clinical Wellness Team (CWT), composed
                                    of dietitians, health coaches, and doctors who collaborate with members to address
                                    digestive concerns, set SMART goals, and guide them towards better digestive health.
                                    Setting SMART goals is a vital part of this process, but until now, it required direct
                                    involvement from the CWT. Members struggled to track their progress between
                                    appointments, creating a need to productize this goal-setting and tracking experience,
                                    enabling members to independently monitor their progress in real-time."
                        imageSrc={gs3}
                        imageAlt="Description of the image"
                        imageSide="left"
                    />
                </div>
            </div>

            {/* Problem Section */}
            <div className="mb-40 mx-8 text-center">
                <div className="flex justify-center w-full">
                    <TitleWithLine text="Problem" side="center"/>
                </div>
                <div className="text-gray-900 text-2xl leading-relaxed mt-5 max-w-screen-xl mx-auto">
                    How might we design a user-friendly goal tracking and setting experience that empowers our members
                    to set and track progress on goals, while also boosting engagement and Day 7 retention across mobile
                    and web platforms?
                </div>
            </div>

            <div>
                <ImageTextSection
                    title="Our Goal"
                    line1="Our primary objective was to allow the CWT to focus more of their appointment time on addressing core digestive health issues, such as identifying potential food triggers and creating actionable plans, rather than introducing and managing SMART goals. A secondary goal was to productize the experience in a way that makes goal setting and tracking easier and more effective for users and ultimately drive engagement and retention in the app. We believed this streamlined experience would increase the app’s value to members, encouraging them to consistently complete daily actions and return over time, ultimately improving health outcomes."
                    imageSrc={gs4}
                    imageAlt="Description of the image"
                    imageSide="right"
                />
            </div>

            <div className={'pt-10'}>
                <CenteredText text={'Research'}/>
                <ImageTextSection
                    title="User Research"
                    line1="Our research focused on understanding the current process of goal setting and tracking used by the Clinical Wellness Team (CWT). We conducted in-depth conversations with the team to learn how they assist members in setting SMART goals, tracking their progress, and reflecting on their achievements as well as identifying issues reported by member. The aim was to identify how we could productize this process and make it more seamless for users."
                    line2="The CWT had gathered qualitative data, highlighting recurring complaints and frustrations. We analyzed these pain points to pinpoint inefficiencies. Our goal was to create a solution that addressed these cracks in the experience and allowed users to set, track, and reflect on their goals with ease, ultimately enhancing their overall engagement and success."
                    imageSrc={gs5}
                    imageAlt="Description of the image"
                    imageSide="left"
                />

                <div className={'max-w-screen-lg mx-auto'}>
                    <PainPointsSection
                        title="Pain Points"
                        painPoints={painPointsData}
                    />
                </div>

                <div className={'max-w-screen-xl mx-auto'}>
                    <CompetitiveResearch
                        title="Competitive Research"
                        content="To inform the design process and our design strategy, I conducted competitive research by analyzing how leading goal setting and tracking apps like Todoist, GoalsWizard, Habit, Strides and Progress approached design."
                        bulletPoints={competitiveResearchPoints}
                        imageSrc={gs6}
                        imageAlt="Competitive Research Screenshots"
                    />
                </div>

                <div className="my-20 mx-8">
                    <div className="w-full max-w-screen-xl mx-auto">
                        <h2 className="text-gray-900 text-2xl font-bold">User Flows</h2>
                        <p className="text-gray-600 mt-5 leading-relaxed">
                            I utilized the product requirements provided by our product manager, along with my knowledge of our current food tracking experience,
                            to identify the various flows and functionalities we need to offer our users. Based on this assessment, I developed the following user flows:
                        </p>
                        <div className="mt-10 w-full">
                            <img src={gs7} alt="User Flows" className="w-full max-w-screen-md mx-auto" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={'pt-20'}>
                <CenteredText text={'Design and Testing'}/>
                <ImageTextSection
                    title="Low Fidelity Design Ideation"
                    line1="I created several low-fidelity wireframes to map out the different flows required for the food tracking experience. This process allowed us to explore various UI possibilities and ensure we covered all necessary functionalities. By visualizing these early concepts, we were able to present our general approach to the engineering team, ensuring that the design was technically feasible from the outset and aligned with our overall project goals. This early collaboration helped identify potential challenges and streamline the development process."
                />
            </div>

            <FullWidthImage src={gs8} marginY={'my-10'}/>

            <div>
                <div className="flex justify-center w-full mt-32 mb-10">
                    <TitleWithLine text="Solutions to Pain Points" side="center"/>
                </div>

                <PainPointSolution
                    leftImage={gs9} // Replace with actual path to the clock image
                    phoneImage={gs10a} // Replace with actual path to the phone image
                    title1="Pain Point: Limited Time in Appointments to Set Goals"
                    body1="Members felt the effect of limited time during appointments, leading to setting goals that were not necessarily what they wanted or felt they could accomplish."
                    title2="Solution"
                    body2="The goal setting/tracking feature provides members the flexibility to set their goals outside of appointments, allowing for more thought and better goal customization."
                />

                <PainPointSolution
                    leftImage={gs11} // Replace with actual path to the image
                    phoneImage={gs12a} // Replace with actual path to the phone image
                    hexColor={'#F4BBEC'}
                    opacity={0.07}
                    title1="Hesitation to Adjust Goals Between Appointments"
                    body1="Users hesitated to change goals outside appointments, preferring to wait for their care team’s guidance, which limited real-time goal adjustments"
                    title2="Solution"
                    body2="The feature allows users to adjust goals easily at any time, empowering them to make timely changes without waiting for their next appointment."
                />

                <PainPointSolution
                    leftImage={gs13} // Replace with actual path to the clock image
                    phoneImage={gs14a} // Replace with actual path to the phone image
                    title1="Difficulty in Tracking Progress"
                    body1="Users relied on memory or manual methods to track progress, leading to inconsistent and inaccurate tracking and sometime forgetting goals"
                    title2="Solution"
                    body2="The progress tracking part of the feature enables users to monitor their progress consistently, eliminating the need for manual or memory based tracking "
                />

                <PainPointSolution
                    leftImage={gs15} // Replace with actual path to the image
                    phoneImage={gs16a} // Replace with actual path to the phone image
                    hexColor={'#F4BBEC'}
                    opacity={0.07}
                    title1="Over-Reliance on Appointments for Feedback and Reflection"
                    body1="Users felt stuck between appointments, waiting for feedback, validation or joint reflection with the care team before altering goals."
                    title2="Solution"
                    body2="The app provides continuous access to progress and goals along with self reflection prompting, reducing the dependency on appointments for feedback and allowing users to take control of their goal and their progress."
                />

                <PainPointSolution
                    leftImage={gs17} // Replace with actual path to the clock image
                    phoneImage={gs18a} // Replace with actual path to the phone image
                    belowPhoneImage={gs18c} // Replace with actual path to the phone image
                    title1="Inability to Stay Motivated and Engaged"
                    body1="Without the ability to easily track, users struggled to stay motivated and engaged with their goals and thus make progress."
                    title2="Solution"
                    body2="The app provides continuous access to progress and goals along with self reflection prompting, reducing the dependency on appointments for feedback and allowing users to take control of their goal and their progress."
                />
            </div>

            <div className="flex justify-center">
                <div className="max-w-screen-xl mx-8 w-full mt-20 mb-40">
                    <div>
                        <TitleWithLine text="Usability Testing" side="left"/>
                    </div>

                    <p className="mt-6 text-lg">
                        We conducted 12 user interviews, engaging existing members to gain insights into how users perceived the new goal setting and tracking feature.
                        This approach allowed us to test usability and gather feedback from members who had already been through the current goal setting
                        and tracking experience in CWT appointments.
                    </p>

                    <p className="mt-6 text-lg">
                        The results of the usability testing were largely positive. Participants generally found the updated interface intuitive and user-friendly and
                        most users were able to complete every task we asked of them during the tests. The new designs were well-received but there were a few usability
                        issues that became apparent.
                    </p>

                    <ul className="list-disc mt-6 ml-8 text-left font-bold">
                        <li className="mt-4">
                            Users misunderstood the goal scheduling interface, thinking they had to select days rather than unselect them, which was the intended behavior.
                        </li>
                        <li className="mt-4">
                            The one-minute meditation to help users set goals with intention was seen as unnecessary and annoying; users preferred to move on when they felt ready.
                        </li>
                        <li className="mt-4">
                            Two users expressed concerns about receiving multiple reminders for different goals. This led to a decision to send one push notification
                            at a set time each day for all goals. Users would be prompted to opt into notifications after setting their first goal with goal-specific language.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex justify-center mx-8">
                <div className="my-10 w-full max-w-screen-xl">
                    <CenteredText text={'Final Visual Design Solutions'} />

                    <VideoSideBySide
                        title="Setting a new goal"
                        leftVideoSrc={'./videos/goal-setting/GS19.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS20.mp4'}
                    />
                    <VideoSideBySide
                        title="Making progress on a goal"
                        leftVideoSrc={'./videos/goal-setting/GS21.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS22.mp4'}
                    />
                    <VideoSideBySide
                        title="Modifying/editing a goal"
                        leftVideoSrc={'./videos/goal-setting/GS23.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS24.mp4'}
                    />
                    <VideoSideBySide
                        title="Deleting/Archiving a goal"
                        leftVideoSrc={'./videos/goal-setting/GS25.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS26.mp4'}
                    />
                    <VideoSideBySide
                        title="Viewing past goals"
                        leftVideoSrc={'./videos/goal-setting/GS27.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS28.mp4'}
                    />
                    <VideoSideBySide
                        title="Viewing past goals"
                        leftVideoSrc={'./videos/goal-setting/GS29.mp4'}
                        rightVideoSrc={'./videos/goal-setting/GS30.mp4'}
                    />
                </div>
            </div>

            <div>
                <CenteredText text={'Results'}/>
                <ImageTextSection
                    title="Metrics and Results"
                    line1="This feature is being built in Q4 of this year. While I don’t have access to business metrics yet, I’m hopeful we’ll achieve our primary goal of freeing up more CWT appointment time to focus on core digestive health issues, like identifying food triggers and creating actionable plans. Additionally, we aim to improve engagement and retention, making the app more valuable to members and potentially leading to better health outcomes."
                    imageSrc={gs31}
                    imageAlt="Coming soon"
                    imageSide="left"
                />
            </div>

            <div className="flex justify-center">
                <div className="space-y-8 mt-10 mb-32 w-full max-w-screen-xl mx-8 flex flex-col">
                    {/* Improvements Section */}
                    <div>
                        <div className="flex justify-center">
                            <TitleWithLine text="Improvements" />
                        </div>
                        <p className="mt-4">
                            There’s a lot of functionality we’re looking to add in future iterations, including:
                        </p>
                        <ul className="list-disc pl-5 mt-2 space-y-2">
                            <li>
                                Allowing the CWT to suggest goal changes when users are underperforming, with a "Suggested by CWT" tag that users can approve or deny.
                            </li>
                            <li>
                                Introducing reflection points where users receive automatic feedback on their progress, helping them adjust goals based on data rather than memory. This feature will require more backend development, which was beyond the MVP scope.
                            </li>
                        </ul>
                        <p className="mt-4">
                            Additionally, the company will need to set up robust data analytics to ensure they’re making the right choices and to determine if they’re meeting the initial goal of freeing up CWT appointment time to focus on core digestive health issues and improving user engagement and retention.
                        </p>
                    </div>

                    {/* In Hindsight Section */}
                    <div className="pt-20">
                        <div>
                            <TitleWithLine text="In Hindsight" />
                        </div>
                        <p className="mt-4">
                            We underestimated the impact of setting multiple goals with various reminders. This led to backend issues with duplicate notifications and risked overwhelming users with excessive alerts—counterproductive when trying to drive engagement with a new feature.
                            Resolving this required coordination with engineering to ensure the right balance of push notifications without overloading users. This experience reinforced the importance of thorough user testing, reminding us that for every assumption we account for, there are always a few we overlook.
                        </p>
                    </div>
                </div>
            </div>

            <Footer selected={'home'}/>
        </div>
    );
}

export default GoalSettingFeature;
