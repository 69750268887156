import React from 'react';

const CenteredText = ({ text }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <span className="h-1 w-[110px] bg-blue-600 my-2"></span>
            <h2 className="text-5xl font-bold my-2 relative text-center">{text}</h2>
            <span className="h-1 w-[166px] bg-blue-600 mt-4"></span>
        </div>
    );
};

export default CenteredText;
