import React from 'react';
import TitleWithLine from '../components/TitleWithLine';

const CompetitiveResearch = ({ title, content, bulletPoints, imageSrc, imageAlt }) => {
    return (
        <div className="my-40 mx-8">
            <div className="flex justify-center w-full">
                <TitleWithLine text={title} side="center" />
            </div>
            <div className="mt-10 text-gray-900 text-lg leading-relaxed">
                <p>{content}</p>
                <ul className="list-disc list-inside mt-5 space-y-2">
                    {bulletPoints.map((point, index) => (
                        <li key={index} className="text-gray-700">
                            {point}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-10 w-full">
                <img src={imageSrc} alt={imageAlt} className="w-full max-w-screen-xl mx-auto" />
            </div>
        </div>
    );
}

export default CompetitiveResearch;
