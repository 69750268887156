import React from 'react';
import greenArrow from '../images/green-arrow.png';

// Helper function to convert hex color to rgba
const hexToRgba = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    // Check if hex is in the shorthand form (e.g. #03F)
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
};

const PainPointSolution = ({ hexColor = '#FFFFFF', opacity = 1, leftImage, phoneImage, belowPhoneImage, title1, body1, title2, body2 }) => {
    const backgroundColor = hexToRgba(hexColor, opacity);

    return (
        <div style={{ backgroundColor }} className="px-8 py-[80px] max-w-screen-xl mx-auto">
            {/* Main Flex Container with gap between left and right */}
            <div className="flex flex-col lg:flex-row justify-between items-center lg:space-x-16">

                {/* Left Section: Image and Text */}
                <div className="flex items-center lg:w-1/2 mb-8 lg:mb-0 pb-20">
                    <img src={leftImage} alt="Left Section Image" className="w-32 h-32 mr-4" />
                    <div>
                        <h2 className="text-xl font-bold mb-2">{title1}</h2>
                        <p>{body1}</p>
                    </div>
                </div>

                {/* Right Section: Text, Arrow, and Phone */}
                <div className="flex flex-col lg:w-1/2 justify-end items-center relative">
                    <div className="flex lg:flex-row items-center lg:justify-end space-x-4">
                        {/* Phone Image */}
                        <div className="flex flex-col items-center relative flex-shrink-0">
                            <img
                                src={phoneImage}
                                alt="Phone Image"
                                className="h-auto w-[175px] min-h-[352px]"
                            />
                            {/* Optional Below Phone Image */}
                            {belowPhoneImage && (
                                <img
                                    src={belowPhoneImage}
                                    alt="Below Phone Image"
                                    className="mt-4 w-[175px] h-auto"
                                />
                            )}
                        </div>
                        {/* Right Text */}
                        <div className="lg:mr-4 mb-4 lg:mb-0">
                            <h2 className="text-xl font-bold mb-2">{title2}</h2>
                            <p>{body2}</p>
                            <img src={greenArrow} alt="Arrow" className="lg:block lg:relative lg:mt-4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PainPointSolution;
