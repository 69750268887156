import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import './index.css';
import HomePage from './pages/HomePage';
import About from './pages/About';
import reportWebVitals from './reportWebVitals';
import TelehealthRedesign from "./pages/TelehealthRedesign";
import SEOOptimization from "./pages/SEOOptimization";
import PetCarePlan from "./pages/PetCarePlan";
import Sendsio from "./pages/Sendsio";
import KoalaVetPortalRedesign from "./pages/KoalaVetPortalRedesign";
import OrderHistory from "./pages/OrderHistory";
import VetSelfSignUp from "./pages/VetSelfSignUp";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import GoalSettingFeature from "./pages/GoalSettingFeature";
import FoodTrackingRedesign from "./pages/FoodTrackingRedesign";

mixpanel.init('3adea6208ca725c20bc77b9c2afaa2bc', {debug: true});

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/about",
        element: <About />,
    },
    {
        path: "/contact",
        element: <Contact />,
    },
    {
        path: "/telehealth-funnel",
        element: <TelehealthRedesign />,
    },
    {
        path: "/seo-optimization",
        element: <SEOOptimization />,
    },
    {
        path: "/pet-care-plan",
        element: <PetCarePlan />,
    },
    {
        path: "/sendsio",
        element: <Sendsio />,
    },
    {
        path: "/koala-vet-portal-redesign",
        element: <KoalaVetPortalRedesign />,
    },
    {
        path: "/koala-order-history",
        element: <OrderHistory />,
    },
    {
        path: "/koala-self-sign-up",
        element: <VetSelfSignUp />,
    },
    {
        path: "/goal-setting",
        element: <GoalSettingFeature />,
    },
    {
        path: "/food-tracking-redesign",
        element: <FoodTrackingRedesign />,
    },
    {
        path: "*",
        element: <Error404 />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
