import {useEffect, useRef} from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/about.css'
import profilePic from '../images/about-me-photo.png'
import Recomendation from "../components/Recommendation";

import recImg1 from '../images/review-image-1.jpeg';
import recImg2 from '../images/review-image-2.jpeg';
import recImg3 from '../images/review-image-3.jpeg';
import recImg4 from '../images/review-image-4.png';
import recImg5 from '../images/review-image-5.png';
import recImg6 from '../images/review-image-6.png';
import KarenImage from '../images/Karen.jpeg';
import JulianImage from '../images/Julian.jpeg';
import JackieImage from '../images/Jackie.jpeg';
import SpinningCube from "../components/SpinningCube";
import useOnScreen from "../hooks/useOnScreen";

function About() {
    const ref1 = useRef(null);
    const isInView1 = useOnScreen(ref1);
    const ref2 = useRef(null);
    const isInView2 = useOnScreen(ref2);

    useEffect(() => {
        mixpanel.track('view about');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'about'}></Header>
            <div>
                <div className={'first-section'}>
                    <div className={'first-column fade-in-right'} ref={ref2}>
                        <div className={'about-title'}>Nice to meet you! *shakes hand*</div>
                        <div className={'below-title'}>Designer | Artist | Self-proclaimed Comedian | Cookie Lover</div>
                        <div className={'about-me'}>
                            My name is Michelle Malik and I’m a product designer who combines ideation, research, prototyping, and visual design to produce functional and clean solutions to complex problems.
                            <br />
                            <br />
                            I’ve worked on personal and professional design projects and have come to learn that being a designer is not only about creating pleasant user experience, but also thinking empathetically, constantly evolving and engineering seamless and intuitive user interactions.
                            <br />
                            <br />
                            I’m an enthusiastic, motivated and empathetic designer with a passion for working with others to make user experiences more innovative and meaningful one interaction at a time!
                        </div>
                    </div>
                    <div className={'second-column mx-auto fade-in-left'} ref={ref1}>
                        <SpinningCube imageUrls={[
                            '/cube/1.jpg',
                            '/cube/5.jpg',
                            '/cube/3.jpg',
                            '/cube/4.jpg',
                            '/cube/2.jpg',
                            '/cube/6.jpg',
                        ]} />
                    </div>
                </div>
                <div className={'second-section'}>
                    <div className={'perspective-title'}>
                        A peer's perspective
                    </div>
                    <div className={'recommendation-div'}>
                        <Recomendation
                            title={'CPO - Cylinder'}
                            image={KarenImage} // Use Karen.jpeg image here
                            review={'It has been an absolute pleasure working with Michelle. She is not only an exceptionally talented product designer but also a key contributor to the positive and collaborative culture of our team. From the start, Michelle impressed me with her thoughtful approach...'}
                            name={'Karen Luk'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Head of Design - Cylinder'}
                            image={JulianImage} // Use Julian.jpeg image here
                            review={'I had the incredible privilege of hiring Michelle as my first product designer at Cylinder, and I can’t imagine the team without her. From the moment she joined, Michelle set an incredibly high standard with her meticulous attention to detail, empathy, and leadership...'}
                            name={'Julian Haddad'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Product Manager - Cylinder'}
                            image={JackieImage} // Use Jackie.jpeg image here
                            review={'Michelle is the type of designer every product manager dreams of working with. I’ve partnered with Michelle on several high-impact product initiatives that have been executed effortlessly. Her designs are beautiful. She has a strong eye for the tiny details...'}
                            name={'Jackie Henning'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Head of Product - Koala'}
                            image={recImg1}
                            review={'I had the pleasure of managing Michelle at Koala Health. Over the course of her time here she excelled in every aspect of her job - from customer research and ideation all the way through wireframes and high fidelity design (including UI/UX and visual design). She alwa...'}
                            name={'Stephen Hendel'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Product Designer - Koala'}
                            image={recImg2}
                            review={'Michelle possesses a natural knack for user-centered design. Her eagerness to seek feedback and tackle new challenges head-on speaks volumes about her commitment to growth. What truly stands out is her enthusiasm and dedication to delivering top- ...'}
                            name={'Jamie Sheriff'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Founding Team Engineer - Koala'}
                            image={recImg3}
                            review={'Michelle and I worked together to take Koala\'s vet portal from it\'s unsightly but functional "v1" origins to a "v2" industry leading UI. It was a long journey and on the way she delivered a "v1.5" design in only about a week that vastly elevated the experience from the toy project...'}
                            name={'Nick Francisci'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Head of Design - Fuzzy'}
                            image={recImg4}
                            review={'Michelle is a great product thinker and very thorough in her work. She was invaluable to the team and was super flexible in her design approach, always ensuring the right thing for the user.'}
                            name={'Ivis Mas'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Senior Product Designer - Fuzzy'}
                            image={recImg5}
                            review={'Michelle is one of the most positive people I\'ve met and extremely eager to learn. Not only is she fantastic at all the researching that comes with product design, but she also is an amazing designer and comes up with quality solutions. She\'s one of the most supportive...'}
                            name={'Jen Belken'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                        <Recomendation
                            title={'Product Manager - Fuzzy'}
                            image={recImg6}
                            review={'Michelle was a pleasure to work with on the Product & Design team. Michelle was always available and open to questions and discussions regarding design and functionality. With her can-do attitude, she was always willing to take on any challenge. She worked...'}
                            name={'Hannah Zhang'}
                            url={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'}
                        />
                    </div>
                    <a href={'https://www.linkedin.com/in/michellebmalik/details/recommendations/'} className={'custom-button'}>
                        View all recommendations
                    </a>
                </div>
            </div>
            <Footer selected={"about"}/>
        </div>
    );
}

export default About;
