import React from 'react';

const FullWidthImage = ({ src, alt, marginY = 'my-20' }) => {
    return (
        <div className={`${marginY} mx-8`}>
            <img className="w-full max-w-screen-xl mx-auto" src={src} alt={alt} />
        </div>
    );
};

export default FullWidthImage;
