import React, {useEffect} from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import heroImage from '../images/cs/food-tracking-redesign/FT1.png';
import gs2 from '../images/cs/goal-setting/GS2.png';
import gs3 from '../images/cs/goal-setting/GS3.png';
import gs4 from '../images/cs/food-tracking-redesign/FT4.png';
import gs5 from '../images/cs/goal-setting/GS5.png';
import gs6 from '../images/cs/food-tracking-redesign/FT6.png';
import gs7 from '../images/cs/food-tracking-redesign/FT7.png';
import gs8 from '../images/cs/food-tracking-redesign/FT8.png';
import gs9 from '../images/cs/food-tracking-redesign/FT9.png';
import gs10 from '../images/cs/food-tracking-redesign/FT10.png';
import gs11 from '../images/cs/food-tracking-redesign/FT11.png';
import gs12 from '../images/cs/food-tracking-redesign/FT12.png';
import gs13 from '../images/cs/food-tracking-redesign/FT13.png';
import gs14 from '../images/cs/food-tracking-redesign/FT14.png';
import gs15 from '../images/cs/food-tracking-redesign/FT15.png';
import gs16 from '../images/cs/food-tracking-redesign/FT16.png';
import gs17 from '../images/cs/food-tracking-redesign/FT17.png';
import gs18 from '../images/cs/food-tracking-redesign/FT18.png';
import gs37 from '../images/cs/food-tracking-redesign/FT37.png';
import gs38 from '../images/cs/food-tracking-redesign/FT38.png';
import PortfolioHero from "../components/PortfolioHero";
import CenteredText from "../components/CenteredText";
import TitleWithLine from "../components/TitleWithLine";
import ImageTextSection from "../components/ImageTextSection";
import PainPointsSection from "../components/PainPointsSection";
import CompetitiveResearch from "../components/CompetitiveResearch";
import FullWidthImage from "../components/FullWidthImage";
import VideoSideBySide from "../components/VideoSideBySide";
import PainPointSolutionImageToImage from "../components/PainPointSolutionImageToImage";

const painPointsData = [
    {
        title: "Visibility Issue",
        description: "Users found it difficult to see food results due to the UI colors, with white text on a green background making it hard to read."
    },
    {
        title: "Interaction Difficulty",
        description: "The small size of the food result tiles led to users often clicking the wrong result, causing frustration."
    },
    {
        title: "Inconsistent Design",
        description: "The food tracking experience felt disconnected from the rest of the app, with many users complaining about the inconsistent design."
    },
    {
        title: "Confusion in Food Tracking Flow (end part)",
        description: "Users mistakenly believed they had completed the tracking process after selecting a food result, often clicking the back button instead of the 'Save' button."
    },
    {
        title: "Icon Visibility",
        description: "Users struggled to locate the barcode or meal scan icon, preventing them from easily completing those actions."
    },
    {
        title: "Frequent Food Entry",
        description: "Users found re-typing and searching for foods they track often tedious and wanted a simpler way to track frequently eaten foods."
    },
    {
        title: "Recipe Creation and Tracking Confusion",
        description: "Users did not understand how to create a recipe within the app, leading to frustration and were frustrated having to re-enter ingredients when tracking the same recipe, leading to dropoff."
    }
];

const competitiveResearchPoints = [
    "What problem does their product address, and how does it relate to the challenge we’re trying to solve?",
    "What motivates competitors to choose their specific design and approach?",
    "Why do people trust/use a competitor’s product?",
    "Does their product maintain a competitive edge over other solutions in the market? How so?",
    "What solutions are they providing to their users?",
    "What aspects of their approach do I find effective, and what areas could be improved?"
];

const FoodTrackingRedesign = () => {
    useEffect(() => {
        mixpanel.track('view case study - food tracking redesign');
    }, []);

    return (
        <div className="pt-5 mx-auto">
            <Header selected={'home'}/>
            <PortfolioHero
                backgroundColor="#F4BBED"
                textColor={'black'}
                headerText="Food Tracking Experience Redesign"
                bodyText="Cylinder Health is dedicated to empowering individuals with personalized care plans, SMART goals, immediate access to a dedicated care team, tailored content and courses, and ongoing support to help members optimize their gut health."
                heroImage={heroImage}
            />

            <div className={'my-32'}>
                <p className="italic text-base lg:text-lg mb-4 mx-8 flex justify-center mb-10">
                    <div className={'max-w-screen-xl'}>
                        <span className="font-bold">Disclaimer:</span> You may notice that the visual design/branding
                        changes in this case study. That's because <span
                        className="font-bold italic">*drumroll*</span> Cylinder underwent a major rebrand, allowing us
                        to update our branding to a more modern aesthetic. Check out a sneak peek below!
                    </div>
                </p>
                <FullWidthImage marginY={''} src={gs2}/>
            </div>

            <div className="flex justify-center mx-8">
                <div className="my-10 w-full max-w-screen-xl">
                    <CenteredText text={'Background'}/>
                    <ImageTextSection
                        title="Context"
                        line1="A crucial aspect of Cylinder’s offerings is the tracking of food, symptoms, and stool, which enables our care team to analyze the relationships between diet and symptoms thus our primary goal was to create an exceptional food tracking experience. This would allow us to collect essential data, deliver more personalized guidance to our members, and enhance engagement and retention, particularly in the crucial first week of using our service."
                        imageSrc={gs3}
                        imageAlt="Description of the image"
                        imageSide="left"
                    />
                </div>
            </div>

            {/* Problem Section */}
            <div className="mb-40 mx-8 text-center">
                <div className="flex justify-center w-full">
                    <TitleWithLine text="Problem" side="center"/>
                </div>
                <div className="text-gray-900 text-2xl leading-relaxed mt-5 max-w-screen-xl mx-auto">
                    How might we design a user-friendly food tracking experience that empowers members to track and gain
                    insights from our care team while increasing engagement and Day 7 retention across mobile and web
                    platforms?
                </div>
            </div>

            <div>
                <ImageTextSection
                    title="Our Goal"
                    line1="Our primary objective was to increase engagement and retention via utilization of the food tracking feature. We observed low engagement levels and learned from our care team that members frequently neglected to use the food tracking tool, despite its importance in providing personalized care. We aimed to develop an intuitive, user-friendly interface that would simplify the food tracking process, encouraging more consistent use. Additionally, this enhancement was critical in preparing for an upcoming initiative that would demand a higher level of detail and data collection from our members."
                    imageSrc={gs4}
                    imageAlt="Description of the image"
                    imageSide="right"
                />
            </div>

            <div className={'pt-10'}>
                <CenteredText text={'Research'}/>


                <div>
                    <div className={'flex justify-center mt-20'}>
                        <TitleWithLine text={'The Current User Experience'} side={'both'}/>
                    </div>
                    <div className={'text-center text-xl my-5'}>
                        Below is a depiction of the experience at the start of this initiative
                    </div>
                    <div className={'flex justify-center'}>
                        <video className={`w-full max-w-[350px] m-2`} controls>
                            <source src={'./videos/food-tracking/FT4a.mp4'} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <ImageTextSection
                    title="User Research"
                    line1="I began by analyzing the food tracking experience to identify confusing or unintuitive elements. Conversations with our Clinical Wellness team revealed key pain points frequently raised by users. I explored how members might perceive the experience, where drop-offs occurred, and whether the design aligned with our brand, shaping a set of user test questions."
                    line2='I then conducted user/usability interviews, asking questions like, "What are your first thoughts when you see this experience?" and "Are there any parts you particularly like or dislike?" Observing users complete tasks provided insights into how they navigated the flow. This along with their open feedback revealed critical pain points and formed the foundation for the redesign.'
                    imageSrc={gs5}
                    imageAlt="Description of the image"
                    imageSide="left"
                />

                <div className={'max-w-screen-lg mx-auto'}>
                    <PainPointsSection
                        title="Pain Points"
                        painPoints={painPointsData}
                    />
                </div>

                <div className={'max-w-screen-xl mx-auto'}>
                    <CompetitiveResearch
                        title="Competitive Research"
                        content="To inform the design process and our design strategy, I conducted competitive research by analyzing how leading food tracking apps like MyFitnessPal, Noom, WeightWatchers, Fooducate, Lose It!, and MyNetDiary approached their designs."
                        bulletPoints={competitiveResearchPoints}
                        imageSrc={gs6}
                        imageAlt="Competitive Research Screenshots"
                    />
                </div>
            </div>

            <div>
                <CenteredText text={'Design and Testing'}/>
                <ImageTextSection
                    title="Low Fidelity Design Ideation"
                    line1="I created several low-fidelity wireframes to map out the different flows required for the food tracking experience. This process allowed us to explore various UI possibilities and ensure we covered all necessary functionalities. By visualizing these early concepts, we were able to present our general approach to the engineering team, ensuring that the design was technically feasible from the outset and aligned with our overall project goals. This early collaboration helped identify potential challenges and streamline the development process."
                />
            </div>

            <FullWidthImage src={gs7} marginY={'my-10'}/>

            <div>
                <div className="flex justify-center w-full mt-32 mb-10">
                    <TitleWithLine text="Solutions to Pain Points" side="center"/>
                </div>

                <PainPointSolutionImageToImage
                    leftImage={gs8}
                    rightImage={gs9}
                />

                <PainPointSolutionImageToImage
                    leftImage={gs10}
                    rightImage={gs11}
                    hexColor={'#F4BBED'}
                    opacity={0.07}
                />

                <PainPointSolutionImageToImage
                    leftImage={gs12}
                    rightImage={gs13}
                />

                <PainPointSolutionImageToImage
                    leftImage={gs14}
                    rightImage={gs15}
                    hexColor={'#F4BBED'}
                    opacity={0.07}
                />

                <PainPointSolutionImageToImage
                    leftImage={gs16}
                    rightImage={gs17}
                />
            </div>

            <div className="mt-20 mb-40 mx-8 flex justify-center">
                <div className={'max-w-screen-xl'}>
                    <div>
                        <TitleWithLine text="Usability Testing" side="left"/>
                    </div>

                    <p className="mt-6 text-lg">
                        We conducted 10 user interviews, engaging both existing members and non-members, to gain
                        insights into how users perceived the recent changes. This approach allowed us to gather
                        feedback from individuals familiar with the previous experience as well as those encountering
                        the new design for the first time.
                    </p>

                    <p className="mt-6 text-lg">
                        The results of the usability testing were largely positive. The new designs were well-received,
                        with no substantial usability issues reported. Participants generally found the updated
                        interface intuitive and user-friendly and were able to complete every task we asked of them
                        during the tests.
                    </p>

                    <p className="mt-6 text-lg">
                        However, some users noted that certain flows felt lengthy due to the amount of information
                        requested. This feedback was anticipated and was an important aspect of our testing objectives.
                        We moved forward with our plan to make certain elements of the experience optional. For example,
                        adding ingredients to foods was designed to be an optional step.
                    </p>
                </div>
            </div>

            <FullWidthImage marginY={''} src={gs18}/>

            <div className="mt-10 mb-40 mx-8 flex justify-center">
                <div className={'max-w-screen-xl'}>
                    <div>
                        <TitleWithLine text="Rebrand" side="left"/>
                    </div>

                    <p className="mt-6 text-lg">In Q2 of 2024, we launched our major rebrand, implementing new brand
                        guidelines across our design system. We meticulously updated every mobile and web experience to
                        align with the rebranding efforts. The results were highly satisfying; although the core
                        functionality remained unchanged, the overall experience now feels significantly cleaner and
                        more refined.</p>
                </div>
            </div>

            <div className="flex justify-center mx-8">
                <div className="my-10 w-full max-w-screen-xl">
                    <CenteredText text={'Final Visual Design Solutions'}/>

                    <VideoSideBySide
                        title="Tracking a food via search"
                        leftVideoSrc={'./videos/food-tracking/FT19.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT20.mp4'}
                    />
                    <VideoSideBySide
                        title="Tracking a custom food"
                        leftVideoSrc={'./videos/food-tracking/FT21.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT22.mp4'}
                    />
                    <VideoSideBySide
                        title="Tracking a custom recipe"
                        leftVideoSrc={'./videos/food-tracking/FT23.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT24.mp4'}
                    />
                    <VideoSideBySide
                        title="Creating a new recipe"
                        leftVideoSrc={'./videos/food-tracking/FT25.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT26.mp4'}
                    />
                    <VideoSideBySide
                        title="Creating a new food"
                        leftVideoSrc={'./videos/food-tracking/FT27.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT28.mp4'}
                    />
                    <VideoSideBySide
                        title="Editing/Deleting a tracked food"
                        leftVideoSrc={'./videos/food-tracking/FT29.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT30.mp4'}
                    />
                    <VideoSideBySide
                        title="Tracking a food by scanning meal/bar code"
                        leftVideoSrc={'./videos/food-tracking/FT31.mp4'}
                    />
                    <VideoSideBySide
                        title="Editing or removing an added recipe"
                        leftVideoSrc={'./videos/food-tracking/FT33.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT34.mp4'}
                    />
                    <VideoSideBySide
                        title="Removing an added food"
                        leftVideoSrc={'./videos/food-tracking/FT35.mp4'}
                        rightVideoSrc={'./videos/food-tracking/FT36.mp4'}
                    />
                </div>
            </div>

            <div>
                <CenteredText text={'Results'}/>

                <div className={'flex justify-center mt-20'}>
                    <div className="flex justify-between max-w-screen-xl mx-8 gap-4 lg:gap-20">
                        <div className="w-full sm:w-[49%] mb-4 flex-grow-0">
                            <img
                                src={gs37}
                                alt="Image 1"
                                className="w-full h-auto object-cover max-w-[350px]"
                            />
                        </div>
                        <div className="w-full sm:w-[49%] mb-4 flex-grow-0">
                            <img
                                src={gs38}
                                alt="Image 2"
                                className="w-full h-auto object-cover max-w-[350px]"
                            />
                        </div>
                    </div>
                </div>

                <div className={'flex justify-center'}>
                    <div className="my-8 max-w-screen-xl mx-8">
                        <TitleWithLine text="Metrics and Results"/>

                        <div className="text-left mt-4">
                            <ul className="list-disc pl-5">
                                <li>
                                    <strong>Day 7 Retention:</strong> Retention rates improved by 3%, indicating that
                                    the redesigned experience potentially kept users engaged during the critical first
                                    week.
                                </li>
                                <li className="mt-2">
                                    <strong>Daily Active Users (DAUs):</strong> While exact figures aren't available, we
                                    saw an uptick in average DAU's post-launch, a key indicator of increased feature
                                    adoption and recurring engagement with the app.
                                </li>
                                <li className="mt-2">
                                    <strong>User Feedback:</strong> The Clinical Wellness Team reported consistently
                                    positive feedback, with members mentioning they used the food tracking feature more
                                    frequently. This qualitative insight points to improved usability and a more
                                    engaging experience.
                                </li>
                                <li className="mt-2">
                                    <strong>Product Goals Met:</strong> The enhancements also prepared the platform for
                                    future initiatives requiring more detailed tracking and data collection, aligning
                                    with long-term product objectives for more robust user interaction.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'flex justify-center mx-8'}>
                <div className={'max-w-screen-xl'}>
                    <div className="flex justify-center">
                        <TitleWithLine text="Improvements"/>
                    </div>
                    <p className={'font-bold text-xl'}>Innovation</p>
                    <div>
                        <p>In the future, I envision developing a more advanced approach to gaining food trigger insights by leveraging AI to automate the identification of potential food triggers. Currently, the process of linking foods to symptoms and stool patterns is highly manual, requiring significant time and expertise from nutritionists and dieticians—resources that are often limited. While I understand that creating such an AI tool would be a significant lift, the benefits for users would far outweigh the complexity, multiplying the value they get from the app by delivering actionable insights at scale. Though the process is intricate, it is based on tabular data, making it feasible with the right resources.</p>
                        <br />
                        <p>To bring this idea to life, the plan would involve the following steps:</p>
                        <br />
                        <ol className="list-decimal list-inside ml-4 text-md">

                        <li><strong>Data Collection:</strong> Gather enough food, symptom, and stool data over a time frame yet to be determined, ensuring the AI model has sufficient information to accurately predict food triggers.</li>
                            <li><strong>Proof of Concept:</strong> Use this data from a small group of users to create an initial proof of concept, testing the AI's ability to identify potential food triggers.</li>
                            <li><strong>AI Training:</strong> Train the model using the collected data, refining its ability to predict food triggers based on the patterns detected.</li>
                            <li><strong>Model Validation:</strong> Continuously validate and adjust the AI model to improve its accuracy and ensure it provides meaningful insights.</li>
                            <li><strong>User Testing and Feedback:</strong> Roll out the tool to a broader group of users, gather feedback, and further refine the model to maximize its impact.</li>
                        </ol>
                        <br/>
                    </div>
                    <p className={'font-bold text-xl mt-4'}>Data Analysis</p>
                    <p className="mt-2">
                        While we were unable to fully implement specific data tracking for our redesigned food tracking
                        feature due to limitations in our Looker dashboards, there were several key performance
                        indicators (KPIs) I would have wanted to track if we had the capability. These metrics would
                        have provided a clearer picture of user engagement, adoption, and retention, giving us more
                        actionable insights for future iterations:
                    </p>
                    <ul className="list-disc pl-5 mt-4 space-y-2">
                        <li><strong>Average Food Logs per User per Day</strong>: Measure daily food entries to assess
                            engagement.
                        </li>
                        <li><strong>Daily Users Engaging with the Feature</strong>: Track unique daily interactions to
                            evaluate adoption.
                        </li>
                        <li><strong>Average Session Length</strong>: Analyze time spent on food tracking to identify
                            ease or friction in the user experience.
                        </li>
                        <li><strong>Percentage of Users Returning to Feature After Day 1</strong>: Track short-term
                            retention to gauge feature stickiness.
                        </li>
                        <li><strong>New Users Adopting Food Tracking</strong>: Measure the adoption rate of new users to
                            see if the redesign appeals to first-time users.
                        </li>
                        <li><strong>Drop-off Rate in the Tracking Process</strong>: Identify where users stop logging to
                            detect usability issues.
                        </li>
                    </ul>
                    <p className="mt-4">
                        Although we lacked the resources to track these KPIs at the time, they represent key metrics
                        that would have provided a more data-driven approach to evaluating the success of the food
                        tracking redesign.
                    </p>
                </div>
            </div>

            <div className="flex justify-center">
                <div className="space-y-2 mt-10 mb-32 w-full max-w-screen-xl mx-8 flex flex-col">
                    {/* Improvements Section */}
                    <p className={'font-bold text-xl'}>General</p>
                    <div>
                        We plan to streamline longer user flows while still capturing key data, like food amounts and
                        servings for an upcoming initiative. Feedback showed this process was too time-consuming, and
                        users struggled to provide details on the spot. Our goal is to simplify these interactions,
                        making it quicker and easier for users to input accurate information without compromising data
                        quality. This will enhance the experience while supporting future product needs.
                    </div>

                    {/* In Hindsight Section */}
                    <div className="pt-20">
                        <div>
                            <TitleWithLine text="In Hindsight"/>
                        </div>
                        <p className="mt-4">
                            We realized our messaging around the food tracking redesign could have been clearer. While
                            users were informed about the changes, we didn’t fully explain why additional details, like
                            serving sizes, were needed. Providing context—especially that these changes were in response
                            to user requests—could have helped members better understand and accept the update,
                            potentially reducing negative feedback about the increased data input.
                        </p>
                    </div>
                </div>
            </div>

            <Footer selected={'home'}/>
        </div>
    );
}

export default FoodTrackingRedesign;
