import React from 'react';
import TitleWithLine from '../components/TitleWithLine';

const PainPointsSection = ({ title, painPoints }) => {
    return (
        <div className="my-40 mx-8">
            <div className="flex justify-center w-full">
                <TitleWithLine text={title} side="center" />
            </div>
            <div className="mt-10 space-y-10">
                {painPoints.map((point, index) => (
                    <div key={index}>
                        <h3 className="text-gray-900 text-xl font-semibold">{point.title}</h3>
                        <p className="text-gray-600 mt-2">{point.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PainPointsSection;
