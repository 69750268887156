import React from 'react';

const TitleWithLine = ({ text, side = 'both' }) => {
    return (
        <div className="flex items-center">
            {side !== 'right' && <span className="w-8 h-1 bg-pink-300 mx-2"></span>}
            <h2 className="text-2xl font-bold mx-2">{text}</h2>
            {side !== 'left' && <span className="w-8 h-1 bg-pink-300 mx-2"></span>}
        </div>
    );
};

export default TitleWithLine;
