import '../css/portfolio-item.css';
import {useNavigate} from 'react-router-dom';
import arrow from '../images/portfolio-arrow.svg';

function PortfolioItem({url, coverImage, jobImage, jobTitle, description}) {
    const navigate = useNavigate();

    const navigateAndScroll = (url) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate(url);
    }

    return (
        <div className="portfolio-item-container cursor-pointer mb-20" onClick={navigateAndScroll.bind(this, url)}>
            {/* Top Section with Job Image, Company Name, Description, and Arrow */}
            <div className="flex items-center" >
                {/* Job Image */}
                <div className="flex items-center space-x-3">
                    <img
                        className="job-image h-20 w-20 rounded-xl"
                        src={jobImage}
                        alt="Job Icon"
                    />

                    {/* Job Title and Description */}
                    <div>
                        <div className={'flex flex-row gap-3'}>
                            <h2 className="text-xl font-semibold text-base text-gray-900">{jobTitle}</h2>
                            <img
                                src={arrow}
                                alt="Arrow Icon"
                            />
                        </div>
                        <p className="text-md text-gray-500 mt-2">{description}</p>
                    </div>
                </div>
            </div>

            {/* Full-width Cover Image */}
            <div className="mt-6">
                <img
                    className="cover-image w-full rounded-md"
                    src={coverImage}
                    alt="Cover"
                />
            </div>
        </div>
    );
}

export default PortfolioItem;
