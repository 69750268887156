import React from 'react';

const VideoSideBySide = ({ leftVideoSrc, rightVideoSrc, title }) => {
    return (
        <div className="my-20">
            <div className="text-lg font-bold mb-4">
                {title}
            </div>
            <div className={`flex ${rightVideoSrc ? 'justify-between' : 'justify-start'} flex-wrap`}>
                <video className={`w-full ${rightVideoSrc ? 'max-h-[525px] max-w-[262px]' : 'max-w-[750px]'} m-2`} controls>
                    <source src={leftVideoSrc} type="video/mp4" />
                </video>
                {rightVideoSrc && (
                    <video className="w-full max-w-[750px] m-2" controls>
                        <source src={rightVideoSrc} type="video/mp4" />
                    </video>
                )}
            </div>
        </div>
    );
};

export default VideoSideBySide;
