import React from 'react';

const PortfolioHero = ({ backgroundColor, headerText, bodyText, heroImage, textColor = 'white' }) => {
    return (
        <div className="w-full min-h-[400px] flex justify-center items-center p-8" style={{ backgroundColor }}>
            <div className="w-full max-w-screen-xl flex flex-col lg:flex-row justify-between items-center relative">
                <div className={`flex-1 min-w-[300px] max-w-[600px] z-20 ${textColor === 'black' ? 'text-black' : 'text-white'}`}>
                    <h1 className="text-[46px] lg:text-[56px] mb-4">{headerText}</h1>
                    <p className="text-base lg:text-lg">{bodyText}</p>
                </div>
                <div className="flex-shrink-0 flex justify-center w-full lg:w-[40%] mt-10 lg:mt-0">
                    <img src={heroImage} alt="Hero feature" className="w-full h-auto relative lg:top-20" />
                </div>
            </div>
        </div>
    );
};

export default PortfolioHero;
